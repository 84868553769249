<template>
    <div
        class="relative w-full h-full aspect-4/3 overflow-hidden"
        @mouseover="showVideo"
        @mouseout="hideVideo"
    >
        <NuxtInventoryImage
            :class="[
                'w-full h-full object-contain object-center absolute inset-0 aspect-4/3 z-10',
                showAssetVideo ? 'hidden' : '',
            ]"
            :src="vehicle.image"
            :width="500"
            :ratio="!disableImageCrop ? VehicleImageRatio.RATIO_4_3 : undefined"
            sizes="xs:400px sm:500px"
            :settings="{
                overlay,
                fit: !disableImageCrop ? VehicleImageFit.COVER : undefined,
                position: !disableImageCrop
                    ? VehicleImagePosition.ATTENTION
                    : undefined,
            }"
            :alt="`${vehicle.stock} - ${vehicle.name}`"
            :blur-hash="firstImage?.blur_hash"
        />
        <Player
            v-if="!resetVideoEnabled"
            :video-id="videoAsset.mux_data?.id"
            :initialized="videoInitialized"
            :asset="videoAsset"
            :controls="false"
            :play="showAssetVideo"
            :mute-button-visible="false"
            :title="videoTitle"
        />
        <span
            :class="[
                'absolute right-2 z-30 rounded-full bg-white',
                tagLineDisplayed ? 'bottom-11' : 'bottom-2',
            ]"
        >
            <PlayCircleIcon
                v-if="!showAssetVideo"
                class="h-8 w-8 text-black"
                aria-hidden="true"
            />
        </span>
        <GridVehicleTagline v-if="tagLineDisplayed" :vehicle="vehicle" />
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { PlayCircleIcon } from '@heroicons/vue/20/solid'
import type {
    Vehicle,
    VehicleAsset,
    VehicleImage,
} from '~/utils/types/inventory'
import GridVehicleTagline from '~/components/SearchResultsPage/Vehicle/GridVehicle/GridVehicleTagline.vue'
import Player from '~/components/Player/Player.vue'
import {
    VehicleImageFit,
    VehicleImagePosition,
    VehicleImageRatio,
} from '~/utils/types/inventory/enums'

const domainStore = useDomainStore()
const route = useRoute()

const props = defineProps({
    vehicle: {
        type: Object as PropType<Vehicle>,
        required: true,
    },
    videoAsset: {
        type: Object as PropType<VehicleAsset>,
        required: true,
    },
    firstImage: {
        type: Object as PropType<VehicleImage | null>,
        default: null,
    },
    overlay: {
        type: String as PropType<string | null>,
        default: null,
    },
    showTagLine: {
        type: Boolean,
        default: true,
    },
    intersected: {
        type: Boolean,
        default: false,
    },
    mobileIntersectDelay: {
        type: Number,
        default: 800,
    },
    disableImageCrop: {
        type: Boolean,
        default: false,
    },
})

const videoInitialized = ref(true)
const showAssetVideo = ref(false)
const checkingIntercept = ref(false)
const resetVideoEnabled = ref(false)

watch(
    () => props.intersected,
    (intersected) => {
        if (intersected) {
            if (!checkingIntercept.value) {
                checkingIntercept.value = true

                setTimeout(() => {
                    if (props.intersected) {
                        showVideo()
                    }

                    checkingIntercept.value = false
                }, props.mobileIntersectDelay)
            }
        } else {
            hideVideo()
        }
    },
)

watch(
    () => route.fullPath,
    () => resetVideo(),
)

const tagLineDisplayed = computed(
    () => props.vehicle.tag_line && props.showTagLine,
)

const videoTitle = computed<string>(
    () =>
        `${domainStore.autoZnetworkEntity.name} - #${props.vehicle.stock} - ${props.vehicle.name}`,
)

function showVideo() {
    showAssetVideo.value = true
}

function hideVideo() {
    showAssetVideo.value = false
}

function resetVideo() {
    resetVideoEnabled.value = true
    setTimeout(() => (resetVideoEnabled.value = false), 700)
}
</script>
