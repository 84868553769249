<template>
    <!--        <div class="absolute top-0 left-0 p-2 text-sm"> -->
    <!--            <div -->
    <!--                class="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10" -->
    <!--            > -->
    <!--                Badge -->
    <!--            </div> -->
    <!--        </div> -->
    <div class="flex flex-1 flex-col space-y-1 p-4">
        <div>
            <div
                v-if="vehicle.type === 'NEW'"
                class="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10"
            >
                {{ vehicle.type }}
            </div>
        </div>
        <div class="text-base font-semibold text-gray-900">
            <DealerMeNuxtLink
                :href="`/for-sale/${vehicle.slug}`"
                @click="updatePosition"
            >
                <!--                    <span aria-hidden="true" class="absolute inset-0" /> -->
                <template v-if="vehicle.custom_name">{{
                    vehicle.custom_name
                }}</template>
                <template v-else>
                    {{ vehicle.year }} {{ vehicle.make }}
                    {{ vehicle.model }}
                </template>
            </DealerMeNuxtLink>
        </div>
        <div class="text-sm text-gray-500 inline-flex items-center">
            <span v-if="!vehicle.custom_name && vehicle.trim">{{
                vehicle.trim
            }}</span>
            <span v-if="vehicle.miles">
                <span v-if="!vehicle.custom_name && vehicle.trim">&nbsp;- </span
                >{{ new Intl.NumberFormat().format(vehicle.miles) }}
                {{ t('mi') }}
            </span>
            <VehicleElectricIcon :vehicle="vehicle" />
            <!--                <VehicleElectricIcon -->
            <!--                    :vehicle="vehicleWithCarfax" -->
            <!--                    @click=" -->
            <!--                        modalStore.openModal({ -->
            <!--                            component: 'ElectricVehicleModal', -->
            <!--                            title: 'Electric Vehicle Types', -->
            <!--                            params: { -->
            <!--                                vehicle: props.vehicle, -->
            <!--                            }, -->
            <!--                        }) -->
            <!--                    " -->
            <!--                /> -->
        </div>
        <div class="text-sm text-gray-500">#{{ vehicle.stock }}</div>
        <div class="flex flex-1 flex-col justify-end">
            <VehiclePrice :vehicle="vehicle" unlock-type="text" />
        </div>

        <!--        <div v-if="condition">-->
        <!--            <div>Condition: {{ condition }}</div>-->
        <!--            <div-->
        <!--                v-if="warranty"-->
        <!--                class="flex w-full items-center justify-between text-base"-->
        <!--            >-->
        <!--                <span class="flex items-center">-->
        <!--                    <ShieldCheckIcon class="h-5 w-5 mr-1" />-->
        <!--                    <span>Warranty</span>-->
        <!--                    <QuestionMarkCircleIcon-->
        <!--                        class="h-5 w-5 ml-1 cursor-pointer"-->
        <!--                    />-->
        <!--                </span>-->
        <!--            </div>-->
        <!--        </div>-->

        <VehicleHistory
            v-if="storyblokStore.srpHistoryEnabled && showHistory"
            :type="CTAButtonComponentType.SRP"
            :vehicle="vehicle"
        />

        <VehicleCTAButtons
            v-if="srp && storyblokStore.settingsLoaded"
            :type="CTAButtonComponentType.SRP"
            :vehicle="vehicle"
            :incentives="activeOrganizationIncentives"
            @click="openLeadModal"
        />
    </div>
    <!--        <ElectricVehicleModal -->
    <!--            :modal-open="electricVehicleModalOpen" -->
    <!--            :vehicle="vehicleWithCarfax" -->
    <!--            @close="electricVehicleModalOpen = false" -->
    <!--        /> -->
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
// import { ShieldCheckIcon } from '@heroicons/vue/24/solid'
// import { QuestionMarkCircleIcon } from '@heroicons/vue/24/outline'
import {
    CTAButtonComponentType,
    type LeadButtonData,
} from '~/utils/vdpLeadComponentSchema'
import VehicleCTAButtons from '~/components/Vehicle/VehicleCTAButtons.vue'
import VehiclePrice from '~/components/Vehicle/VehiclePrice.vue'
import VehicleHistory from '~/components/VehicleDescriptionPage/VehicleHistory/Index.vue'
import VehicleElectricIcon from '~/components/Vehicle/VehicleElectricIcon.vue'
import { LeadModalComponent } from '~/utils/types/leadModal'
import { VehicleUnlockType } from '~/utils/types/vehicleUnlock/enums'
import { trackCtaInteractionEvent } from '~/composables/asc/events/CtaInteraction'
import type { Vehicle, VehicleIncentive } from '~/utils/types/inventory'
import {
    ASCElementSubType,
    ASCElementType,
    ASCEventAction,
    ASCEventActionResult,
    ASCPageType,
} from '~/utils/types/asc/enums'
// import { getSetting } from '~/utils/functions'

const storyblokStore = useStoryblokStore()
const vehicleUnlockStore = useVehicleUnlockStore()
const leadModalStore = useLeadModalStore()
const domainStore = useDomainStore()
const inventoryStore = useInventoryStore()

const props = defineProps({
    vehicle: {
        type: Object as PropType<Vehicle>,
        required: true,
    },
    srp: {
        type: Boolean,
        default: false,
    },
    showHistory: {
        type: Boolean,
        default: true,
    },
    position: {
        type: Number,
        default: null,
    },
})

const { t } = useI18n()

// const electricVehicleModalOpen = ref(false)

const activeOrganizationIncentives = computed(() =>
    props.srp
        ? (domainStore.autoZnetworkEntity.incentives ?? []).filter(
              (incentive: VehicleIncentive) => incentive.active,
          )
        : [],
)

// const condition = computed(() => {
//     switch (props.vehicle.type) {
//         case 'USED':
//             return (props.vehicle.certified ? 'Certified ' : '') + 'Pre-Owned'
//         case 'NEW':
//             return 'New'
//         default:
//             return null
//     }
// })

// const warrantySetting = computed(
//     () =>
//         getSetting(
//             domainStore.autoZnetworkSettings,
//             'inventory.certified.warranty',
//         ) ?? null,
// )

// const warranty = computed(() => {
//     if (!warrantySetting.value || !props.vehicle.certified) {
//         return null
//     }
//
//     return {
//         title: warrantySetting.value.title ?? 'Limited warranty',
//         description: warrantySetting.value.description,
//     }
// })

function updatePosition() {
    if (props.position) {
        inventoryStore.resultsPosition = props.position
    }
}

function openLeadModal(button: LeadButtonData) {
    if (button.component === LeadModalComponent.VEHICLE_INCENTIVE) {
        vehicleUnlockStore.openModal(
            VehicleUnlockType.INCENTIVE,
            props.vehicle,
            button.incentive,
            button.formButtonText ?? null,
        )
    } else {
        leadModalStore.openModal(CTAButtonComponentType.SRP, button, {
            vehicle: props.vehicle,
        })
    }

    trackCtaInteractionEvent({
        pageType: props.srp ? ASCPageType.ITEM_LIST : ASCPageType.ITEM,
        data: {
            element_text: button.asc.text || null,
            element_color: button.asc.color || null,
            element_type: ASCElementType.ITEM_DETAILS,
            element_subtype: ASCElementSubType.CTA_BUTTON,
            event_action: ASCEventAction.CLICK,
            event_action_result: ASCEventActionResult.OPEN,
        },
        vehicle: props.vehicle,
    })
}
</script>
