<template>
    <div
        ref="intersectionHolder"
        class="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white sm:group-hover:shadow"
    >
        <GridVehicleMedia
            :vehicle="vehicle"
            :first-image="firstImage"
            :show-tag-line="showTagLine"
            :position="position"
            :intersected="intersected"
        />
        <GridVehicleDetails
            :vehicle="vehicleWithCarfax"
            :srp="srp"
            :show-history="showHistory"
            :position="position"
        />
        <GridVehicleLocation :vehicle="vehicle" />
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { Vehicle, VehicleImage } from '~/utils/types/inventory'
import { onIntersect } from '~/composables/intersectionObserver'
import GridVehicleDetails from '~/components/SearchResultsPage/Vehicle/GridVehicle/GridVehicleDetails.vue'
import GridVehicleLocation from '~/components/SearchResultsPage/Vehicle/GridVehicle/GridVehicleLocation.vue'
import GridVehicleMedia from '~/components/SearchResultsPage/Vehicle/GridVehicle/Media/GridVehicleMedia.vue'
// import { useThemeStore } from '@/Stores/theme'
// import Logo from '@/Pages/Tenant/Components/Logo.vue'

// const themeStore = useThemeStore()
const inventoryStore = useInventoryStore()

const props = defineProps({
    vehicle: {
        type: Object as PropType<Vehicle>,
        required: true,
    },
    position: {
        type: Number,
        default: null,
    },
    showTagLine: {
        type: Boolean,
        default: true,
    },
    showHistory: {
        type: Boolean,
        default: true,
    },
    srp: {
        type: Boolean,
        default: false,
    },
    intersected: {
        type: Boolean,
        default: false,
    },
    carfaxData: {
        type: [Array, null] as PropType<any[] | null>,
        default: null,
    },
})

const emit = defineEmits(['intersected', 'unintersected'])

const { isMobile } = useDevice()

const intersectionHolder = ref<HTMLDivElement>()

onMounted(() =>
    nextTick(() => {
        if (isMobile && intersectionHolder.value) {
            onIntersect({
                element: intersectionHolder.value,
                callback: () => emit('intersected', props.vehicle.id),
                outCallback: () => emit('unintersected', props.vehicle.id),
                threshold: 0.7,
            })
        }
    }),
)

const carfaxDataArr = computed(() =>
    props.carfaxData !== null ? props.carfaxData : inventoryStore.srpCarfaxData,
)

const vehicleWithCarfax = computed<Vehicle>(() => {
    if (carfaxDataArr.value.length === 0) {
        return props.vehicle
    }

    const findCarfax = Array.isArray(carfaxDataArr.value)
        ? carfaxDataArr.value.find(
              (carfaxObj: any) => carfaxObj.id === props.vehicle.id,
          )
        : null

    return {
        ...props.vehicle,
        ...{ carfax: findCarfax?.carfax ?? null },
    }
})

const firstImage = computed<VehicleImage | null>(() =>
    vehicleWithCarfax.value.images ? vehicleWithCarfax.value.images[0] : null,
)
</script>
