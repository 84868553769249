<template>
    <div class="aspect-h-3 aspect-w-4 bg-gray-200">
        <DealerMeNuxtLink
            :href="`/for-sale/${vehicle.slug}`"
            class="flex h-full flex-col items-center justify-center space-y-6"
        >
            <div class="font-bold italic">
                {{ t('photos_coming_soon') }}
            </div>
            <!--                <div> -->
            <!--                    <button class="btn">Request Photos</button> -->
            <!--                </div> -->
        </DealerMeNuxtLink>
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { Vehicle } from '~/utils/types/inventory'

defineProps({
    vehicle: {
        type: Object as PropType<Vehicle>,
        required: true,
    },
})

const { t } = useI18n()
</script>
