<template>
    <div v-if="vehicle.image">
        <GridVehicleMediaGallery
            v-if="showGallery"
            :vehicle="vehicle"
            :overlay="overlay"
            :position="position"
            :intersected="intersected"
            :disable-image-crop="disableImageCrop"
        />
        <GridVehicleMediaSingle
            v-else
            :vehicle="vehicle"
            :first-image="firstImage"
            :overlay="overlay"
            :show-tag-line="showTagLine"
            :position="position"
            :intersected="intersected"
            :disable-image-crop="disableImageCrop"
        />
    </div>
    <GridVehicleMediaMissing v-else :vehicle="vehicle" />
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { Vehicle, VehicleImage } from '~/utils/types/inventory'
import GridVehicleMediaGallery from '~/components/SearchResultsPage/Vehicle/GridVehicle/Media/GridVehicleMediaGallery.vue'
import GridVehicleMediaSingle from '~/components/SearchResultsPage/Vehicle/GridVehicle/Media/GridVehicleMediaSingle.vue'
import GridVehicleMediaMissing from '~/components/SearchResultsPage/Vehicle/GridVehicle/Media/GridVehicleMediaMissing.vue'

const domainStore = useDomainStore()

defineProps({
    vehicle: {
        type: Object as PropType<Vehicle>,
        required: true,
    },
    firstImage: {
        type: Object as PropType<VehicleImage | null>,
        default: null,
    },
    showTagLine: {
        type: Boolean,
        default: true,
    },
    position: {
        type: Number,
        default: null,
    },
    intersected: {
        type: Boolean,
        default: false,
    },
})

const showGallery = computed(
    () => domainStore.inventorySettings?.srpGallery ?? false,
)

const overlay = computed<string | null>(
    () => domainStore.inventorySettings?.overlay ?? null,
)

const disableImageCrop = computed(
    () => domainStore.inventorySettings?.disableImageCrop ?? false,
)
</script>
