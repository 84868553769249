<template>
    <div class="h-full">
        <Swiper
            :modules="[SwiperNavigation]"
            :loop="true"
            :navigation="true"
            class="hidden h-full lg:block"
        >
            <SwiperSlide v-for="(image, index) in vehicle.images" :key="index">
                <div class="overflow-hidden lg:col-span-2">
                    <DealerMeNuxtLink
                        :href="`/for-sale/${vehicle.slug}`"
                        @click="updatePosition"
                    >
                        <div class="z-20 cursor-pointer h-full">
                            <NuxtInventoryImage
                                :src="image.url"
                                :width="500"
                                :ratio="
                                    !disableImageCrop
                                        ? VehicleImageRatio.RATIO_4_3
                                        : undefined
                                "
                                sizes="xs:400px sm:500px"
                                :settings="{
                                    overlay,
                                    fit: !disableImageCrop
                                        ? VehicleImageFit.COVER
                                        : undefined,
                                    position: !disableImageCrop
                                        ? VehicleImagePosition.ATTENTION
                                        : undefined,
                                }"
                                :alt="`${vehicle.name} Image ${index + 1}`"
                                classes="z-20 h-full w-full object-contain object-center"
                            />
                        </div>
                    </DealerMeNuxtLink>
                </div>
            </SwiperSlide>
        </Swiper>
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { Vehicle } from '~/utils/types/inventory'
import {
    VehicleImageFit,
    VehicleImagePosition,
    VehicleImageRatio,
} from '~/utils/types/inventory/enums'

const inventoryStore = useInventoryStore()

const props = defineProps({
    vehicle: {
        type: Object as PropType<Vehicle>,
        required: true,
    },
    overlay: {
        type: String as PropType<string | null>,
        default: null,
    },
    position: {
        type: Number,
        default: null,
    },
    disableImageCrop: {
        type: Boolean,
        default: false,
    },
})

function updatePosition() {
    if (props.position) {
        inventoryStore.resultsPosition = props.position
    }
}
</script>
